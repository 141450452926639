import React, { useEffect, useRef, useState } from "react";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import TableComp from "components/Table";
import ShowNumberOfRecordsComp from "pages/TransactionalLogs/Component/ShowNumberOfRecords";
import { passAuiObject } from "common/utils";
import store from "app/store";
import {
  setClearFilter,
  setPageNumber,
  setPageSize,
} from "pages/Configuration/common/Consumers.slice";
import UsePagination from "pages/Configuration/common/UsePaginationHook";
import { ListNatsConsumers } from "services/api/nats.api";
import CONSTANTS from "common/constants";
import { useHistory, useLocation } from "react-router-dom";
import { NatsConsumerListingStyles } from "./styled";
import ConsumerTabFilterComp from "./ConsumerTabFilterComp";
import CreateConsumerComp from "./CreateConsumer";

interface ITablecolumnHeaders {
  name?: string;
  id?: number;
  clsName?: string;
  filterCode?: string;
  referenceCol?: any;
  isElipsesPopoverDisabled?: boolean;
  isAnchor?: boolean;
  anchorRedirect?: string;
}

interface IqueryParamsAPI {
  pageNumber?: number;
  pageSize?: number;
  search?: string;
  type?: string;
  clientCode?: string;
  streamId?: number;
}

interface IConsumerListProps {
  streamID: number;
}

const useQueryURL = () => {
  return new URLSearchParams(useLocation().search);
};

const ConsumerListingComp = (props: IConsumerListProps) => {
  const consumerListStoreData: IqueryParamsAPI = useSelector((state: any) => {
    return state?.consumerList?.consumerTabFilters;
  });

  const consumerListStoreDataWithStreamID = {
    ...consumerListStoreData,
    streamId: props.streamID,
  };

  UsePagination(setPageNumber);

  const searchFieldRef = useRef<HTMLAuiInputElement>(null);
  const filterCompRef = useRef<any>(null);

  const [, setPopoverSearchErrorMsg] = useState<boolean>(false);

  // Customize Table Columns
  const [columnHeaders] = useState<ITablecolumnHeaders[]>([
    {
      name: "Consumer Name",
      id: 1,
      clsName: "consumer-name-header",
      filterCode: "name",
      isAnchor: true,
      anchorRedirect: CONSTANTS.PAGE_ROUTES.NATS_STREAM_LISTING,
    },
    {
      name: "Client",
      id: 2,
      clsName: "client-header",
      filterCode: "client_name_transform",
      referenceCol: {
        clientName: "clientName",
        clientCode: "clientCode",
      },
    },
    {
      name: "Type",
      id: 3,
      clsName: "type-header",
      filterCode: "natsConsumerConfiguration.type",
    },
    {
      name: "Subscriptions",
      id: 4,
      clsName: "subscription-header",
      filterCode: "subscription_transform",
      referenceCol: "natsConsumerConfiguration.subscriptions",
    },
    // {
    //   name: "Action",
    //   id: 5,
    //   clsName: "action-header",
    //   filterCode: "action-elipses",
    //   isElipsesPopoverDisabled: true,
    // },
  ]);

  /* API Call  */
  const {
    data: consumers,
    refetch,
    isFetching: loading,
  } = useQuery(
    "stream-list",
    () => ListNatsConsumers(consumerListStoreDataWithStreamID),
    {
      select: (data) => {
        return {
          consumersListData: data?.data?.natsConsumers,
          paginationData: {
            ...data._pagination,
            isFirst: Boolean(data._pagination.isFirst),
            isLast: Boolean(data._pagination.isLast),
          },
        };
      },
      onSuccess: (data: any) => {
        if (
          data?.consumersListData?.length === 0 &&
          searchFieldRef?.current?.value
        ) {
          setPopoverSearchErrorMsg(true);

          setTimeout(() => {
            setPopoverSearchErrorMsg(false);
          }, 6000);
        }
      },
    }
  );

  const [reset, setReset] = useState(false);
  const clearFilters = () => {
    filterCompRef?.current?.clearFilters();
    store.dispatch(setClearFilter());
    setReset(!reset);
  };

  /* Refetch table records on change of filters ,num of records and pagination */
  useEffect(() => {
    refetch();
  }, [
    consumerListStoreData.pageSize,
    consumerListStoreData.pageNumber,
    consumerListStoreData.search,
    consumerListStoreData.type,
    consumerListStoreData.clientCode,
  ]);

  useEffect(() => {
    // Cleanup function only on component unmount
    return () => {
      clearFilters();
    };
  }, []);

  const { consumersListData, paginationData } = consumers || {};

  const handleChangeNumOfRecords = (pageSizeSelected: number) => {
    /* Push to store with number of records data */
    store.dispatch(setPageSize(pageSizeSelected));
  };

  const query = useQueryURL();
  const viewParmURL = query.get("view");

  const [changeCompOnView, setChangeCompOnView] = useState(viewParmURL ?? "");
  const history = useHistory();

  const handleClickCreateConsumer = () => {
    // Get the current URL parameters
    const currentUrlParams = new URLSearchParams(window.location.search);

    // Set or update the 'view' query parameter
    currentUrlParams.set("view", "create-consumer");

    // Push the new URL with updated query parameters to history
    history.push(`${window.location.pathname}?${currentUrlParams.toString()}`);
    setChangeCompOnView("create-consumer");
  };

  useEffect(() => {
    refetch();
    store.dispatch(setPageNumber(1));
  }, [changeCompOnView]);

  if (changeCompOnView === "create-consumer") {
    return <CreateConsumerComp setChangeCompOnView={setChangeCompOnView} />;
  }

  return (
    <NatsConsumerListingStyles>
      <div className="header d-flex justify-content-end mb-3-bkp">
        <aui-button
          id="add-nats-consumers-btn"
          buttontitle={CONSTANTS.BREADCRUMB_HEADING.ADD_NATS_CONSUMER}
          buttonclass="aui-modal-launch"
          className="aui-modal-launch"
          variant="link-style-arrow"
          onClick={(e: React.MouseEvent<HTMLAuiButtonElement>) => {
            e.preventDefault();
            handleClickCreateConsumer();
          }}
        />
      </div>

      <ConsumerTabFilterComp
        ref={filterCompRef}
        clearAllFilterFunc={clearFilters}
      />

      <div className="container p-lg-0">
        <TableComp
          theadData={columnHeaders}
          tbodyData={consumersListData}
          isTableLoading={loading}
          clearAllFilterFunc={clearFilters}
          tableName="NATS Consumers"
        />
      </div>

      <div className="heading-lbl-wrapper mt-4 pagination-sec d-flex flex-wrap justify-content-between">
        {consumersListData?.length > 0 && (
          <ShowNumberOfRecordsComp
            totalCount={paginationData?.totalCount}
            onChangeFunc={handleChangeNumOfRecords}
            reset={reset}
          />
        )}

        {consumersListData?.length > 0 && !loading && (
          <div className="col-8 pr-0">
            <aui-pagination
              inputdata={passAuiObject(paginationData)}
              alignment="end"
            />
          </div>
        )}
      </div>
    </NatsConsumerListingStyles>
  );
};

export default ConsumerListingComp;
